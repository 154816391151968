<script>
    import { getContext, onMount } from 'svelte';
    import toast from 'svelte-french-toast';
    import InfoIcon from '../../Icons/InfoIcon.svelte';
    import CashOutline from '../../Icons/CashOutline.svelte';
    import { Button } from '$lib/components/ui/button';

    const settingsWalletContext = getContext('settingsWalletContext');
    const {
        withdrawal_allow_fees,
        withdrawal_default_fee_percentage,
        currency_symbol,
        pendingBalance,
        getWithdrawalAmountLimitations,
        getWithdrawalMinimumAmount,
        getWithdrawalsAllowedPaymentMethods,
        getWithdrawalMaximumAmount,
    } = settingsWalletContext;

    onMount(() => {
        document.addEventListener('DOMContentLoaded', function () {
            Wallet.setPaymentMethodTitle();

            // Deposit amount change event listener
            document.getElementById('withdrawal-amount').addEventListener('change', function () {
                if (!Wallet.withdrawalAmountValidation()) {
                    return false;
                }
            });

            // Checkout proceed button event listener
            document.querySelector('.withdrawal-continue-btn').addEventListener('click', function () {
                Wallet.initWithdrawal();
            });

            document.querySelectorAll('.custom-control').forEach(function (control) {
                control.addEventListener('change', function () {
                    document.querySelector('.withdrawal-error-message').style.display = 'none';
                });
            });

            document.getElementById('payment-methods').addEventListener('change', function () {
                Wallet.setPaymentMethodTitle();
            });
        });

        var Wallet = {
            /**
             * Instantiate withdrawal request
             * @returns {boolean}
             */
            initWithdrawal: function () {
                let submitButton = document.querySelector('.withdrawal-continue-btn');
                updateButtonState('loading', submitButton, trans('Request withdrawal'), 'white');

                if (!Wallet.withdrawalAmountValidation()) {
                    updateButtonState('loaded', submitButton, trans('Request withdrawal'));
                    return false;
                }

                document.querySelector('.withdrawal-error-message').style.display = 'none';

                fetch(app.baseUrl + '/withdrawals/request', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    body: JSON.stringify({
                        amount: document.getElementById('withdrawal-amount').value,
                        message: document.getElementById('withdrawal-message').value,
                        identifier: document.getElementById('withdrawal-payment-identifier').value,
                        method: document.getElementById('payment-methods').selectedOptions[0].textContent,
                    }),
                })
                    .then((response) => response.json())
                    .then((result) => {
                        const msgType = result.success ? 'success' : 'danger';
                        const msgLabel = result.success ? trans('Success') : trans('Error');
                        if (msgType === 'success') {
                            toast.success('Success');
                        } else if (msgType === 'danger') {
                            toast.error('Error');
                        }

                        // append new amounts
                        document.querySelector('.wallet-total-amount').innerHTML = result.totalAmount;
                        document.querySelector('.wallet-pending-amount').innerHTML = result.pendingBalance;

                        // clear inputs
                        document.getElementById('withdrawal-amount').value = '';
                        document.getElementById('withdrawal-message').value = '';
                        document.getElementById('withdrawal-payment-identifier').value = '';

                        // Clearing up error messages
                        document.getElementById('withdrawal-amount').classList.remove('is-invalid');
                        document.getElementById('withdrawal-message').classList.remove('is-invalid');

                        updateButtonState('loaded', submitButton, trans('Request withdrawal'));
                    })
                    .catch((result) => {
                        if (result.status === 422 || result.status === 500) {
                            result.json().then((responseJSON) => {
                                Object.keys(responseJSON.errors).forEach(function (field) {
                                    if (field === 'amount') {
                                        document.getElementById('withdrawal-amount').classList.add('is-invalid');
                                    }
                                    if (field === 'message') {
                                        document.getElementById('withdrawal-message').classList.add('is-invalid');
                                    }
                                });
                            });
                        }
                        updateButtonState('loaded', submitButton, trans('Request withdrawal'));
                    });
            },

            /**
             * Validates the withdrawal amount
             * @returns {boolean}
             */
            withdrawalAmountValidation: function () {
                let withdrawalAmount = document.getElementById('withdrawal-amount').value;
                if (
                    withdrawalAmount.length === 0 ||
                    (withdrawalAmount.length > 0 &&
                        (parseFloat(withdrawalAmount) < parseFloat(app.withdrawalsMinAmount) ||
                            parseFloat(withdrawalAmount) > parseFloat(app.withdrawalsMaxAmount)))
                ) {
                    document.getElementById('withdrawal-amount').classList.add('is-invalid');
                    return false;
                } else {
                    document.getElementById('withdrawal-amount').classList.remove('is-invalid');
                    return true;
                }
            },

            /**
             * Get withdrawal payment identifier based on payment method from dropdown
             * @returns {string}
             */
            getPaymentIdentifierTitle: function () {
                let title;
                switch (document.getElementById('payment-methods').selectedOptions[0].textContent) {
                    case 'Bank transfer':
                        title = 'Bank account';
                        break;
                    case 'Paypal':
                    case 'PayPal':
                        title = 'PayPal email';
                        break;
                    case 'Crypto':
                        title = 'Wallet address';
                        break;
                    case 'Other':
                        title = 'Payment account';
                        break;
                    default:
                        title = 'Payment account';
                        break;
                }
                return title;
            },

            setPaymentMethodTitle: function () {
                let paymentIdentifierTitle = trans(Wallet.getPaymentIdentifierTitle());
                document.getElementById('payment-identifier-label').textContent = paymentIdentifierTitle;
            },
        };
    });
</script>

<div class="d-flex justify-content-between align-items-center mt-3">
    {#if withdrawal_allow_fees && withdrawal_default_fee_percentage > 0}
        <div class="d-flex align-items-center">
            <svelte:component this="{InfoIcon}" />
            <span
                class="text-left"
                id="pending-balance"
                title="The payouts are manually and it usually take up to 24 hours for a withdrawal to be processed, we will notify you as soon as your request is processed."
            >
                A {withdrawal_default_fee_percentage} fee will be applied.
            </span>
        </div>
    {:else}
        <h5></h5>
    {/if}
    <div class="d-flex align-items-center">
        <svelte:component this="{InfoIcon}" />
        <span
            class="text-right"
            id="pending-balance"
            title="The payouts are manually and it usually take up to 24 hours for a withdrawal to be processed, we will notify you as soon as your request is processed."
        >
            Pending balance <b class="wallet-pending-amount">{currency_symbol + '(' + pendingBalance + ')'}</b>
        </span>
    </div>
</div>
<div class="input-group mb-3 mt-3">
    <div class="input-group-prepend">
        <span class="input-group-text" id="amount-label">
            <svelte:component this="{CashOutline}" />
        </span>
    </div>
    <input
        class="form-control"
        placeholder="{getWithdrawalAmountLimitations}"
        aria-label="Username"
        aria-describedby="amount-label"
        id="withdrawal-amount"
        type="number"
        min="{getWithdrawalMinimumAmount}"
        step="1"
        max="{getWithdrawalMaximumAmount}"
    />
    <div class="invalid-feedback">Please enter a valid amount</div>
    <div class="input-group mb-3 mt-3">
        <div class="d-flex w-100 flex-row">
            <div class="form-group w-50 pr-2">
                <label for="paymentMethod">Payment method</label>
                <select class="form-control" id="payment-methods" name="payment-methods">
                    {#each getWithdrawalsAllowedPaymentMethods as paymentMethod}
                        <option value="{paymentMethod}">{paymentMethod}</option>
                    {/each}
                </select>
            </div>
            <div class="form-group w-50 pl-2">
                <label id="payment-identifier-label" for="withdrawal-payment-identifier">Bank account</label>
                <input class="form-control" type="text" id="withdrawal-payment-identifier" name="payment-identifier" />
            </div>
        </div>
        <div class="form-group w-100">
            <label for="withdrawal-message">Message (Optional)</label>
            <textarea placeholder="Bank account, notes, etc" class="form-control" id="withdrawal-message" rows="2"
            ></textarea>
            <span class="invalid-feedback" role="alert">
                Please add your withdrawal notes: EG: Paypal or Bank account.
            </span>
        </div>
    </div>

    <div class="payment-error error text-danger d-none mt-3">Add all required info</div>
    <!-- <button class="btn btn-primary btn-block rounded mr-0 withdrawal-continue-btn" type="submit">Request withdrawal</button> -->
    <Button
        class="w-100 btn btn-block deposit-continue-btn mr-0 mt-4 cursor-pointer rounded-lg !bg-bmn-500 text-white hover:!bg-bmn-600"
        type="submit"
        disabled>Request withdrawal</Button
    >
</div>
